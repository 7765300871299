import gsap from "gsap";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import slugify from "slugify";
import AmenitiesIcons from "./amenities-icons";

const FacilitiesBlock = ({ array, title }) => {
	const [expanded, setExpanded] = useState(false);
	const [buttonBorder, setButtonBorder] = useState(false);
	const sluggedTitle = slugify(title.toLowerCase());

	const handleMouseLeave = () => {
		// setExpanded(false);
		// gsap.to(`.navbar-bottom-${sluggedTitle}`, {
		//   scaleY: 0,
		//   duration: 0.5,
		//   delay: 0.5,
		// });
	};

	const handleOtherClick = () => {
		setExpanded(false);
		gsap.to(
			`.navbar-bottom-${sluggedTitle}`,

			{ scaleY: 0, duration: 0.5, delay: 0.5 }
		);
	};

	const handleClick = () => {
		setExpanded(!expanded);
	};

	return (
		<>
			<h6>{title}</h6>
			<div onMouseLeave={handleMouseLeave}>
				<Row>
					{array?.slice(0, 8).map((item) => {
						return (
							<Col xs={3}>
								<div className="text-center">
									<h2
										className="text-med-grey fs-1"
										style={{ fontSize: "60px" }}
									>
										<AmenitiesIcons lookupValue={item} />
									</h2>
									<p className="text-med-grey">{item}</p>
								</div>
							</Col>
						);
					})}
				</Row>

				{expanded && (
					<div className={`bg-white w-100 position-relative `}>
						{/* <div
              style={{ zIndex: 4 }}
              className={`w-100 d-block dropdown-content-${sluggedTitle} navbar-bottom-${sluggedTitle} bg-white dropdown-gradient-features rounded pb-3`}
            > */}
						<Row>
							{array?.slice(8).map((item) => {
								return (
									<Col xs={3}>
										<div className="text-center">
											<h2
												className="text-med-grey fs-1"
												style={{ fontSize: "60px" }}
											>
												<AmenitiesIcons lookupValue={item} />
											</h2>
											<p className="text-med-grey">{item}</p>
										</div>
									</Col>
								);
							})}
						</Row>
						<Row>
							<Col className="text-center">
								<div className="">
									<p
										className=" text-primary"
										onClick={handleClick}
										style={{ cursor: "pointer" }}
									>
										Hide
									</p>
								</div>
								{/* </div> */}
							</Col>
						</Row>
					</div>
				)}

				{!expanded && array?.length > array?.slice(0, 8).length && (
					<Row>
						<Col className="text-center">
							<div className="">
								<p
									className="text-primary"
									onClick={handleClick}
									style={{ cursor: "pointer" }}
								>
									View all
								</p>
							</div>
						</Col>
					</Row>
				)}
			</div>
		</>
	);
};

export default FacilitiesBlock;

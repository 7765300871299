import React from "react";
import { Col } from "react-bootstrap";

const PricingSecitonOtherOptionsText = ({ type, rentPM }) => {
	return (
		<>
			<Col lg={2} md={3} sm={6}>
				<p>{type}</p>
				<h4>£{rentPM}pm</h4>
			</Col>
		</>
	);
};

export default PricingSecitonOtherOptionsText;

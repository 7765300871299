import React from "react";
import CartContext from "../context/CartContext";
const PricingSecitonText = ({ desksTo, rentPM, floors, name }) => {
	const { numberOfPeople, budgetPerDesk } = React.useContext(CartContext);
	function round5(x) {
		return Math.ceil(x / 5) * 5;
	}
	return (
		<>
			{floors ? <h3>{name}</h3> : ""}
			<blockquote>
				{!floors && (
					<p className="mb-1 fs-2">
						Up to{" "}
						{(numberOfPeople > 0 && numberOfPeople < desksTo
							? numberOfPeople
							: desksTo
						).toLocaleString("en-UK")}{" "}
						desk{desksTo === 1 ? "" : "s"} private office space
					</p>
				)}
				{floors && (
					<p className="mb-1 fs-2">
						Up to {desksTo} desk{desksTo === 1 ? "" : "s"} private floor
					</p>
				)}
				{floors && (
					<h2 className={`mt-1 fs- 1 fw-bold display-${floors ? "6" : "5"}`}>
						£
						{`${
							rentPM === null || rentPM === undefined
								? "POA*"
								: `${round5(rentPM).toLocaleString("en-UK")}pcm*`
						}`}
					</h2>
				)}
				{!floors && (
					<h2 className={`mt-1 fs- 1 fw-bold display-${floors ? "6" : "5"}`}>
						£
						{`${
							rentPM === null || rentPM === undefined
								? "POA"
								: `${(numberOfPeople > 0 && numberOfPeople < desksTo
										? round5(rentPM * numberOfPeople)
										: `${rentPM} per desk`
								  ).toLocaleString("en-UK")}`
						}`}
						*
					</h2>
				)}
			</blockquote>
		</>
	);
};

export default PricingSecitonText;
